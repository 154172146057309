/**
 * Source: https://codepen.io/elainehuang/pen/XVNXrJ?editors=0100
 * FAQ class that reads from any array structure. In this case we are reading from CMS via graphql
 */
import React, { Component } from "react";
import styled from "styled-components";
import { styles, rosepetalsIcon } from "../../utils";
import { StaticQuery, graphql } from "gatsby";

class Accordion extends Component {
  render() {
    const { title, expand, answer, onClick } = this.props;

    return (
      <dl>
        <dt
          className={expand ? "title is-expanded" : "title"}
          onClick={onClick}
        >
          {title}
        </dt>
        <dd
          className={expand ? "content is-expanded" : "content"}
          onClick={onClick}
        >
          <p>{answer}</p>
        </dd>
      </dl>
    );
  }
}

export default class FAQSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = index => () => {
    this.setState({ [`block${index}`]: !this.state[`block${index}`] });
  };

  toggleExpand = (expand = false, itemNum) => () => {
    for (let i = 0; i < itemNum; i++) {
      this.setState({
        [`block${i + 1}`]: expand
      });
    }
  };

  render() {
    const accordionList = [
      { title: "First Accordion" },
      { title: "Second Accordion" },
      { title: "Third Accordion" },
      { title: "Third Accordion" },
      { title: "Third Accordion" }
    ];

    return (
      <ListCardsWrapper img={rosepetalsIcon}>
        <div className="container1">
          <div>
            <h3>Frequently Asked Questions</h3>
            <button
              className="btn1"
              onClick={this.toggleExpand(true, accordionList.length)}
            >
              Expand All
            </button>
            <button
              className="btn1"
              onClick={this.toggleExpand(false, accordionList.length)}
            >
              Collapse All
            </button>
          </div>

          <dl className="accordion1">
            <StaticQuery
              query={FAQQUERYQL}
              render={data => {
                const faqQueryData = data.faqQuery.edges;
                return faqQueryData.map((item, index) => (
                  <Accordion
                    key={index}
                    title={index + 1 + " - " + item.node.question}
                    answer={item.node.answers.answers}
                    onClick={this.toggle(index + 1)}
                    expand={this.state[`block${index + 1}`]}
                  />
                ));
              }}
            />
          </dl>
        </div>
      </ListCardsWrapper>
    );
  }
}

/**************************************/
//          Graphql Constants
/**************************************/
const FAQQUERYQL = graphql`
  {
    faqQuery: allContentfulFaq(sort: { fields: [index], order: ASC }) {
      edges {
        node {
          index
          question
          answers {
            answers
          }
        }
      }
    }
  }
`;

/**************************************/
//          ServicesWrapper SC
/**************************************/
const ListCardsWrapper = styled.div`
  /* background: url(${props => props.img}) center/cover fixed no-repeat; */
  height: 100%;
  padding:1.5rem 2rem 4.3rem;
  background: ${styles.colors.mainLightGreybgClr};
  .container1 {
    min-height: 100%;        
    border-radius: 5px;    
    padding: 35px 20px;    
    text-align: center;      
    h3 {
      font-size:2.2rem;
      color: ${styles.colors.mainBlueColor};     
      text-transform:capitalize;
      text-align: center;      
      text-shadow: 1px 1px 0 #eee;      
      margin-bottom:3rem;
    }  

    .btn1 {
      display: inline-block;
      margin-bottom: 20px;      
      border: 1px solid ${styles.colors.mainBlueColor};
      /* border: 1px solid #1569a8; */
      background: white;      
      color: ${styles.colors.mainBlueColor};
      /* color: #1569a8; */      
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 15px;
      font-size: 15px;
      cursor: pointer;
      outline: none;

      &:hover {
        background: ${styles.colors.mainBlueColor};
        color: white;
        transition: 0.5s;
      }
    }
  }

 
  .accordion1 {
    margin: 0 auto;
    width: 90%;

    .title {      
      position: relative;
      background: ${styles.colors.mainWhite};      
      color: ${styles.colors.mainSubtitleClr};
      /* color: #939393; */
      transform: translate3d(0, 0, 0);      
      font-size: 1.1rem;      
      /* text-shadow: 1px 1px 0 #eee; */
      margin-bottom: -1px;
      border-bottom: 1px solid #e4e4e4;
      /* border-bottom: 1px solid #0e4671; */
      text-align: left;
      padding: 18px 15px;
      cursor: pointer;

      &::after {
        content: "+";
        font-size: 18px;
        /* color: white; */
        color: ${styles.colors.mainBlueColor};     
        transition: transform 0.5s ease-in-out;
        position: absolute;
        right: 30px;
        font-family: monospace;
      }

      &.is-expanded {
        transition: background 0.5s;
        /* background: #0e4671; */

        &::after {
          content: "-";
          transform: rotate(-360deg);
        }
      }
    }

    .content {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.5s;
      margin: 0;
      padding: 0 30px;
      border: solid 1px #eeeeee;
      border-top: 0;
      background: #e8f4fc;

      p {
        padding: 30px 0;
        margin: 0;
        opacity: 0;
        transition: 0.5s;
        font-size:1rem;
      }

      &.is-expanded {
        max-height: 500px;
        overflow: hidden;
        p {
          opacity: 1;
        }
      }
    }

    /* &:after {
      width: 100%;
      height: 10px;
      display: block;
      background: #eee;
      content: "";
    } */
  }
  /** responsive design **/
  @media (max-width: 768px) {    
    padding:1.5rem 0.4rem 3rem;
    .accordion1 {     
     width: 100%;
       .title {
         font-size:1rem;
       }      
    }
    p{
      font-size:0.9rem;
    }
    h3{
     font-size:1.8rem !important;
    }
    .btn1{       
      margin-right: 5px !important;
      font-size: 12px !important;
      
    }

   }
`;
