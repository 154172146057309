import { styles, mailIcon, rosepetalsIcon } from "../../utils/";
import styled from "styled-components";
import ContactForm from "./ContactForm";
import React from "react";
import { StaticQuery, graphql } from "gatsby";
// import { FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";

const ContactList = ({ contactTitle, contactDescription }) => {
  return (
    <TowColWrapper img={rosepetalsIcon}>
      <StaticQuery
        query={FOOTERQUERY}
        render={data => {
          const footerInfo = data.footerPageQuery.edges[0];
          const { fContactTitle } = footerInfo.node;
          const fContactDescription =
            footerInfo.node.fContactDescription.fContactDescription;
          return (
            <div>
              <div className="left-half cell">
                <article>
                  <img src={mailIcon} alt="get in touch" />
                  <h1>{fContactTitle || contactTitle}</h1>
                  <p>{fContactDescription || contactDescription}</p>
                </article>
              </div>
              <div className="right-half cell">
                <ContactForm className="contact-form" />
              </div>
            </div>
          );
        }}
      />
    </TowColWrapper>
  );
};
/* background:url(${props => props.img}) center/cover fixed no-repeat;
  margin: 3rem 0rem 0rem 0rem; */
/* background:${styles.colors.mainLightGreybgClr}; */
/************************************/
/** Two Columns Styled Component */
/************************************/
const TowColWrapper = styled.section`

  background: url(${props => props.img}) center/cover fixed no-repeat;
  /* ${styles.blueGradient}; */
  .container {
    display: table;
    height: 100%;
    width: 100%;
    font-size: 1.25rem;
    line-height: 150%;   
  }
  h1 {
    font-size: 2rem;
    margin: 0 0 0.75rem 0;
    color:${styles.colors.mainWhite};
    text-transform:uppercase;
    letter-spacing:0.2rem;
  }
  p{
    color:#ccf;
    line-height:1.8rem;
    /* letter-spacing:0.08rem; */    
  }
  .cell {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    /* text-align: center; */
    
  }
  .left-half {
    width:60%;
    padding: 4rem;
    /* background: ${styles.colors.mainDarkBlueColor}; */
    background: transparent;
    color: ${styles.colors.mainWhitebgClr};
    text-align: center;
  }
  .right-half {    
    padding: 1rem 2rem;    
    background: transparent;
    color: ${styles.colors.mainDarkBlueColor};        
  }
  img{
    width: auto;
    height: 200px;
    margin-bottom:2.4rem;
  }

  /* Stacked elements vetically on small screen  */
  @media (max-width: 767px) {
    .cell {
      display: table;
      width: 100%;
    }
    img{
    width: auto;
    height: 128px;    
    }
    h1{
      font-size:1.45rem;
    }
    p{
      font-size:1rem;
    }
  }
`;
/**************************************/
const FOOTERQUERY = graphql`
  {
    footerPageQuery: allContentfulFooter {
      edges {
        node {
          fContactTitle
          fContactDescription {
            fContactDescription
          }
        }
      }
    }
  }
`;

ContactList.defaultProps = {
  contactTitle: "Let's have a talk",
  contactDescription:
    "Thank you for reaching out to us. Need additional information or have a question? Please feel free to leave us a message or a brief about your project. Our beloved team is always open to discuss your vision and thoughts. Please fill up the contact form, and we'll do our best to get back to you within one business day."
};

export default ContactList;
